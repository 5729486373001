<template>
    <div class="da">
        <div class="jiazia">
            <van-loading v-show="jiazai" />
        </div>
        <div class="top_bei">
            <!-- <div class="top_text">手机话费快充</div> -->
            <input type="number" class="shu" placeholder="请输入手机号" v-model="phone" >
            <img src="../assets/icon_close@2x (3).png" @click="qingchu" class="close" alt="">
            <img src="../assets/icon_phone@2x (1).png" @click="tong" class="tong" alt="">
            <div class="line"></div>
        </div>
        <button @click="anniu">按钮</button>
        <div class="yunying">
            <div class="yunzi">
               运营商 
            </div>
            <div :class="hh1"  @click='dian1'>
                <div class="jiu">
                    <img src="https://kytapp.oss-cn-shanghai.aliyuncs.com/image/20230620/1687229539521zgyd.png" class="tu" alt="">
                    <div class="zi">
                        {{ brand_name1 }}
                    </div>
                </div>
            </div>
            <div :class="hh2" @click="dian2">
                <div class="jiu">
                <img src="https://kytapp.oss-cn-shanghai.aliyuncs.com/image/20230620/1687229512852zgdx.png" class="tu" alt="">
                <div class="zi">
                    {{ brand_name2 }}
                </div>
            </div>
            </div>
            <div :class="hh3" @click="dian3">
                <div class="jiu">
                <img src="https://kytapp.oss-cn-shanghai.aliyuncs.com/image/20230620/1687229560207zglt.png" class="tu" alt="">
                <div class="zi">
                    {{ brand_name3 }}
                </div>
            </div>
            </div>
        </div>
        <div class="miane">
            <div class="chong_text">
                充值面额
            </div>
            <div class="xia">

                <div class="price" v-for="(item,index) in price" @click="dian(index,item)" >
                    <img src="../assets/icon_sign@2x (2).png" class="price_tu" alt="">
                    <div v-if="vip==1" class="zhe">
                        95.5折
                    </div>
                    <div v-if="vip==0" class="zhe">
                        96折
                    </div>
                    <!-- <div  class="zhe">
                        95.5折
                    </div> -->
                    <div class="p_top" >
                        {{item.amount}}元
                    </div>
                   
                    <div class="p_bottom">
                        优惠后
                        <span  v-if="vip==1" class="hong">{{item.vip_price}}元</span>
                        <span  v-if="vip==0" class="hong">{{item.paid_amount}}元</span>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="zhifu">
            <div class="zhizi">
                支付方式
            </div>
            <div class="jiu2">

                <img src="../assets/wechat-pay-fill@2x.png" class="weitu" alt="">
                <div class="wei">微信支付</div>
                <!-- <van-checkbox  v-model="checked"  class="xuan"></van-checkbox> -->
                <img src="../assets/选择@2x (1).png" class="xuantu" alt="">
            </div>
            <div>
                <!-- {{ code }}
                {{ data1 }}code<br>
                {{ data66 }}验证登录<br>
                {{ toutoken }}头部token<br>
                {{ data2 }}获取订单<br>
                {{ kan }} 上一个参数<br>
                {{ data3 }}<br> -->
            </div>
            <div class="chong" @click="chongzhi">
                充值
            </div>
            <div class="chongti">
                充值提醒 :
            </div>
            <div class="zi_xia">
                <div class="oneyi">
                    <img src="../assets/icon_num1@2x (1).png" class="one_img" alt="">
                    <div class="ziyi">
                        充值<span class="hongzi">0~10分钟</span>内到账(特殊情况稍有延迟)，若超出24小时未到账，平台会在24小时内自动退款，原路退回支付账户中。
                    </div>
                </div>
                <div class="oneer">
                    <img src="../assets/icon_num2@2x (1).png" class="one_img" alt="">
                    <div class="ziyi">
                        <span class="hongzi1">携号转网的手机号</span>无法使用，请勿拍！ 
                    </div>
                </div>
                <div class="onesan">
                    <img src="../assets/icon_num3@2x (1).png" class="one_img" alt="">
                    <div class="ziyi">
                         请确保手机号下单正确并正常使用的手机号，错误输入手机号、空号、查封号，下单不退款。170等虚拟号段暂时不支持充值。
                    </div>
                </div>
            </div>
            <div class="hang">
                <img src="../assets/容器 1@2x.png" @click="jilu" class="zuisan" alt="">
                <div class="zuizi" @click="jilu">充值记录</div>
                <img src="../assets/容器@2x (6).png" class="zuisan1"  @click="zhezhao" alt="">
                <div class="zuizi" @click="zhezhao">客服咨询</div>
            </div>
        </div>
        <img src="../assets/banner_share@2x (3).png" class="ditu" @click="erweitu" alt="">
        <van-overlay :show="show" @click="show = false" >
            <div class="antu">
                <img class="beitu" src="https://xxzs.kytapp.com/storage/pics/huabeijing.png" alt="">
                <img src="https://xxzs.kytapp.com/logo_zhushou.jpg" mode="" class="tutou"/>
                <img src="https://xxzs.kytapp.com/storage/pics/zhushou.png" mode="" class="tuwei"  show-menu-by-longpress="true" />
                <div class="tuzi">助手官方客服</div>
                <div class="tuphone">18151114091</div>
                <div class="tuan">
                <img src="https://xxzs.kytapp.com/storage/pics/muzhi.png" mode="" class="tuzhi"/> 
                <div class="tuanzi">长按二维码添加</div></div>
                <div class="cha1" bindtap="guan">
                </div>
            </div>
    </van-overlay>
    </div>
</template>

<!-- <script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script> -->
<script>
import {
    setToken,getToken
} from '../util/auth'
import { Toast } from 'vant';
import request from "..//plugin/request";
import wx from 'weixin-js-sdk'
     export default {
            name:'applyText',
             data(){
                  return {
                    jiazai:false,
                    show:false,
                     phone:"",
                     apply:'',
                     isActive1:true,
                     isActive2:false,
                     isActive3:false,
                     price:[1,2],
                     aa : [],
                     checked:false,
                     brand_name1:"",
                     brand_name2:"",
                     brand_name3:"",
                     zhifuid:"",
                     token:"",
                     vip:"",
                     code:"",
                     data1:0,
                     data2:1,
                     data3:2,
                     kan:666,
                     data66:"66666",
                     toutoken:""
                 }
          },
 computed: {
    hh1() {
      if (this.isActive1) {
        return 'active'; // 当isActive为true时，添加'active'类名
      } else {
        return 'yi'; // 否则不添加任何类名
      }
    },
    hh2() {
      if (this.isActive2) {
        return 'active2'; // 当isActive为true时，添加'active'类名
      } else {
        return 'er'; // 否则不添加任何类名
      }
    } ,
    hh3() {
      if (this.isActive3) {
        return 'active2'; // 当isActive为true时，添加'active'类名
      } else {
        return 'san'; // 否则不添加任何类名
      }
    },
    pp(){
       
    },
  
  },
  mounted(){
    // /app/sty/getPayment  type=3 device: "mobile"
    // /app/dby/allGoods category_id: 174605192613893
    // let currentQuery = this.$route.query;
    // this.apply = currentQuery.from;
    // this.$nextTick(()=> {
    //   document.forms[0].acceptCharset='GBK'
    //   document.forms[0].submit()
    // })
    console.log(333333333)
    this.aa=document.querySelectorAll(".price")
    console.log(this.aa[0])
     this.aa[0].className="price1"
  },
  beforeCreate(){
    this.jiazai=true
  },
  async created(){
    // let that = this
    // // 监听返回
    // this.pushHistory()
    // window.addEventListener(
    //   'popstate',
    //   function(e) {
    //     console.log(666655555)
    //     console.log(e)
    //     //为了避免只调用一次
    //     that.pushHistory('title1')
    
    //     // that.goBack()
    //     wx.miniProgram.switchTab({url: '/pages/home/index'});

    //   },
    //   false
    // )


    // this.jiazai=true
    console.log('hahhahah')
    console.log(this.isActive)
    console.log(this.$route.query,66)
    // this.phone=this.$route.query.phone
    const params = new URLSearchParams(location.search);
    this.phone = params.get('phone');
    this.vip = params.get('vip'); 
    this.token = params.get('token'); 
    this.code = params.get('code'); 
    setToken(params.get('token'))
    this.toutoken=getToken()
    const data = await request.post("/app/sty/getPayment",{type:3,device:"H5"});    
    const data1 = await request.post("/app/dby/allGoods",{category_id: 174605192613893,device:"H5"});    
    console.log(data.data)
    this.price=data.data
    this.zhifuid=data.data[0].id
    console.log(data.data[0].id,"这里")
    console.log(data1.data)
    this.brand_name1=data1.data.data[0].brand_name
    this.brand_name2=data1.data.data[1].brand_name
    this.brand_name3=data1.data.data[2].brand_name
    this.jiazai=false
  },
  methods:{
    anniu(){
        console.log(111)
        
    },
    // pushHistory(str = 'title', url = '#') {
    //   let state = {
    //     title: str,

    //     url,
    //   }
    //   window.history.pushState(state, state.title, state.url)
    // },
    tong(){
        if(!this.token){
                wx.miniProgram.navigateTo({
    url: '/pages/index/denglu?hh=1', //小程序地址
       });
          }else{
              wx.miniProgram.navigateTo({
          url: '/pages/pay/tong', //小程序地址
      });
          }
        console.log(3333)
    },
    erweitu(){
        if(!this.token){
                wx.miniProgram.navigateTo({
    url: '/pages/index/denglu?hh=1', //小程序地址
       });
          }else{

              wx.miniProgram.navigateTo({
          url: '/pages/pay/erwei', //小程序地址
      });
          }
    },
    zhezhao(){
        // this.show=true
        wx.miniProgram.navigateTo({
    url: '/pages/pay/kefu', //小程序地址
});
    },
    jilu(){
        if(!this.token){
                wx.miniProgram.navigateTo({
    url: '/pages/index/denglu?hh=1', //小程序地址
       });
          }else{

              console.log(22222)
           wx.miniProgram.navigateTo({
          url: '/pages/Phonebill/callrecords', //小程序地址
      });
          }
    },
   async chongzhi(){
    // this.jiazai=true
        console.log(231321231)
        //判断手机号
        let regs = /^((13[0-9])|(17[0-1,6-8])|(15[^9,\\D])|(18[0-9])|(19[0-9]))\d{8}$/
      if(!regs.test(this.phone)){
        Toast('手机号码格式有误')
    // }
        // if(!(/^1[3456789]\d{9}$/.test(this.phone))){ 
        // Toast('请输入正确手机号码');  
        return
     } else {
   
        console.log('可以了 ')
        console.log(this.zhifuid)
        // /app/sty/phoneLimitation   device: "mobile"  phone: "17803877749"
        const data = await request.post("/app/sty/phoneLimitation ",{phone:"17803877749"});    
        console.log(data)
        if(!this.token){
                wx.miniProgram.navigateTo({
    url: '/pages/index/denglu?hh=1', //小程序地址
       });
          }else{
            this.jiazai=true
              //判断是否已经有充值中订单
            //   if(data.data==1){
                   this.data1 = await request.post("/login/getMiniSession",{code:this.code});    
                   
                 
                  // /login/getMiniSession   code: "0a325z1w3wP4923rYW0w3klUdu425z1W" (可拿到openid)
                  this.data2 = await request.post("/app/sty/createPhoneOrder",{
                      channel: "weixinmini",
                    //   device: "pc",
                      goods_id: this.zhifuid,
                      phone: this.phone,
                      share_user: 0,
                      share_user_phone: 0,
                    //   token: this.token
                  }); 
                 this.kan={
                      channel: "weixinmini",
                    //   device: "pc",
                      goods_id: this.zhifuid,
                      phone: this.phone,
                      share_user: 0,
                      share_user_phone: 0,
                    //   token: this.token
                  }
                //   this.data3 = await request.post("/app/sty/payPhoneOrder",{
                //     // device: "H5",
                //     mini: "wdzsWXminiConfig",
                //     openid: this.data1.data.openid,
                //     order_id: this.data2.data.id,
                //     pay_type: 3,
                //     // token: this.token
                //   }); 
                  console.log(this.data1)
                  console.log(this.data2)
                //   console.log(this.data3)
                //   this.data66=await request.post("/app/dby/dbytest",{});
      //             /app/sty/createPhoneOrder
      //             channel: "weixinmini"
      // device: "mobile"
      // goods_id: 10
      // phone: "17803877749"
      // share_user: 0
      // share_user_phone: 0
      // token: "ac6e2a46-cc52-4e40-b15b-d74c72aa1b15"(可拿到订单id  记着里的id)
      
      // /app/sty/payPhoneOrder   device: "mobile"
      // mini: "wdzsWXminiConfig"
      // openid: "o9UDy0HU07iY9FdHElj0OPaWQZZ4"
      // order_id: 909
      // pay_type: 3
      // token: "ac6e2a46-cc52-4e40-b15b-d74c72aa1b15"
    //   wx.miniProgram.postMessage({
    //     data: {
    //       info: this.data3
    //     }
    //   })
            //   var zhifu=    JSON.stringify(this.data3.data.wx)
            this.jiazai=false
                wx.miniProgram.navigateTo({
          url: '/pages/pay/pay?pd='+ this.data1.data.openid+'&o='+this.data2.data.id+'&t=1', //小程序地址
          })
            //   }else{
            //       Toast(data);  
            //   }
          }
     }
    },
    qingchu(){
        this.phone=""
    },
    onChange(){
        this.checked=!this.checked
        console.log(333)
    },
    dian(cc,dd){
        console.log(cc)
        console.log(dd)
        this.zhifuid=dd.id
        console.log(this.zhifuid)
      
      console.log(this.aa)
    //   for(var i=0; i<aa.length;i++){
    //     if(i==cc){
    //     }else{
    //         console.log(a[i])
    //         aa[i].className="price"
    //     }
    //   }
    var bb=document.querySelector(".price1")
    console.log(bb)
    bb.className="price"
    for(var i=0;i<this.aa.length;i++){
        console.log(22)
        if(i==cc){
            this.aa[i].className="price1"
        }
    }
    },
    dian1(){
        console.log(1111)
        this.isActive1=true
        this.isActive2=false
        this.isActive3=false
        console.log(this.isActive)

    }
    ,
    dian2(){
        console.log(1111)
        this.isActive1=false
        this.isActive2=true
        this.isActive3=false
        console.log(this.isActive)
    },
    dian3(){
        console.log(1111)
        this.isActive1=false
        this.isActive3=true
        this.isActive2=false
        console.log(this.isActive)
    }
  }
}
</script>

<style scoped>

.jiazia{
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 999;
    text-align: center;
    width: 100px;
    height: 100px;
}
.tuphone{
    font-size: 14px;
font-family: Source Han Sans CN, Source Han Sans CN;
font-weight: 400;
color: #000000;
margin-left: 0px;
    margin-top: 0px;
}
.tuzi{
    font-size: 16px;
font-family: Source Han Sans CN, Source Han Sans CN;
font-weight: 700;
color: #000000;
margin-left: 0px;
    margin-top: 0px;
}
.tutou{
    width: 56px;
    height: 56px;
    margin-left: 0px;
    margin-top: 0px;
}
.antu{
width: 311px;
height: 450px;
position: fixed;
top: 0px;
left: 0px;  
bottom: 0px;
right: 0px;
margin: auto;
}
.beitu{
    width: 100%;
    height: 450px;
}
.onesan{
    display: flex;
    margin-top: -7px;
}
.oneyi{
    display: flex;
}
.jiu2{
    display: flex;
    margin-top: 15px;
}
.jiu{
    display: flex;
}
.ditu{
    width: 375px;
    height: 116px;
    margin-top: 9px;
}
.zuizi{
    font-size: 12px;
font-family: PingFang SC, PingFang SC;
font-weight: normal;
color: #3074FF;
margin-left: 6px;
margin-top: 2px;
}
.zuisan{
    width: 20px;
    height: 20px;
}
.zuisan1{
    width: 20px;
    height: 20px;
    margin-left: 42px;
}
.hang{
    display: flex;
    margin-left: 80px;
    margin-top: 15px;
}
.one{
    margin-top: -7px;
    /* display: flex; */
}
.oneer{
    display: flex;
    margin-top: -7px;
}
.hongzi{
    font-size: 16px;
    font-weight: 400;
    color: #E55F2A ;
}
.hongzi1{
    font-size: 13px;
    font-weight: 400;
    color: #E55F2A ;
}
.ziyi{
    font-size: 13px;
    font-weight: 400;
    color: #555555;
    width: 280px;
    margin-left: 4px;
    margin-top: 7px;
    /* line-height: 19px; */
}
.zi_xia{
    margin-top: 0;
}
.one_img{
    width: 15px;
    height: 15px;
    margin-left: 16px;
    margin-top: 10px;
}
.chongti{
    font-size: 14px;
font-family: PingFang SC, PingFang SC;
font-weight: 400;
color: #333333;
margin-left: 15px;
margin-top: 15px;
}
.chong{
    width: 315px;
height: 48px;
background: #3074FF;
border-radius: 152px 152px 152px 152px;
opacity: 1;
margin-left: 15px;
margin-top: 20px;
font-size: 18px;
font-family: PingFang SC, PingFang SC;
font-weight: normal;
color: #FFFFFF;
text-align: center;
line-height: 48px;
}
.xuantu{
    width: 22px;
    height: 22px;
    margin-left: 184px;
    margin-top: 0px;
    /* float: left; */
}
/* .xuan{
    margin-left: 303px;
    margin-top: -20px;
} */
.wei{
    font-size: 16px;
font-family: PingFang SC, PingFang SC;
font-weight: normal;
color: #3D3D3D;
margin-top: 3px;
    margin-left: 8px;
}
.weitu{
    width: 32px;
    height: 32px;
    margin-left: 15px;
    margin-top: 0px;
}
.zhizi{
    font-size: 16px;
font-family: PingFang SC, PingFang SC;
font-weight: normal;
color: #3D3D3D;
margin-left: 15px;
}
.zhifu{
    width: 345px;
height: 370px;
background: #FFFFFF;
opacity: 1;
border-radius: 20px;
margin-left: 15px;
margin-top: 10px;
padding-top: 15px;
}
.zhe{
    font-size: 11px;
font-family: PingFang SC, PingFang SC;
font-weight: 400;
color: #895F3D;
float: left;
margin-left: -46px;
margin-top: -8px;
}
.price_tu{
    width: 63px;
    height: 30px;
    float: left;
    margin-left: -6px;
    margin-top: -14px;

}
.p_top{
    font-size: 20px;
font-family: PingFang SC, PingFang SC;
font-weight: normal;
color: #3D3D3D;
/* margin-left: 32px; */
margin-top: 20px;
text-align: center;
}
.p_bottom{
    font-size: 12px;
font-family: PingFang SC, PingFang SC;
font-weight: normal;
color: #AEAEAE;
/* margin-left: 17px; */
text-align: center;
margin-top: 2px;
}
.hong{
    
color: #F53829;
}
.xia{
    display: flex;
    flex-wrap: wrap;
    margin-left: 4px;
    margin-top: 10px;
}
.price{
    width: 108px;
height: 87px;
background: #FFFFFF;
border-radius: 6px 6px 6px 6px;
opacity: 1;
border: 1px solid #DDDDDD;
margin-left: 11px;
}
.price1{
    width: 108px;
height: 87px;
background: rgba(245,56,41,0.05) ;
border-radius: 6px 6px 6px 6px;
opacity: 1;
border: 1px solid #F53829 ;
margin-left: 11px;
}
.chong_text{
    font-size: 16px;
font-family: PingFang SC, PingFang SC;
font-weight: normal;
color: #3D3D3D;
margin-left: 15px;
}
.miane{
    width: 345px;
height: 149px;
background: #FFFFFF;
opacity: 1;
border-radius: 20px;
margin-left: 15px;
margin-top: 10px;
padding-top: 15px;
}
.active{
    width: 98px;
height: 52px;
background: rgba(245,56,41,0.05) ;
border-radius: 6px 6px 6px 6px;
opacity: 1;
border: 1px solid #F53829 ;
margin-left: 15px;
display: inline-block;
margin-top: 10px;
}
.active2{
    width: 98px;
height: 52px;
background: rgba(245,56,41,0.05) ;
border-radius: 6px 6px 6px 6px;
opacity: 1;
border: 1px solid #F53829 ;
margin-left: 11px;
display: inline-block;
margin-top: 10px;
}
.zi{
    font-size: 14px;
font-family: PingFang SC, PingFang SC;
font-weight: 400;
color: #3D3D3D;
display: inline-block;
float: left;
margin-left: 6px;
margin-top: 16px;
}
.tu{
    width: 20px;
    height: 20px;
    margin-left: 8px;
    margin-top: 16px;
}
.yunzi{
    font-size: 16px;
font-family: PingFang SC, PingFang SC;
font-weight: normal;
color: #3D3D3D;
margin-left: 15px;
    margin-top: 16px;
}
.yunying{
    /* display: flex; */
    width: 345px;
    height: 114px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 20px;
    margin-top: -53px;
    margin-left: 15px;
    border: 1px solid #FFFFFF;
}
.yi{
width: 98px;
height: 52px;
background: #FFFFFF;
border-radius: 6px 6px 6px 6px;
opacity: 1;
border: 1px solid #DDDDDD;
margin-left: 15px;
display: inline-block;
margin-top: 10px;
}
.er{
width: 98px;
height: 52px;
background: #FFFFFF;
border-radius: 6px 6px 6px 6px;
opacity: 1;
border: 1px solid #DDDDDD;
margin-left: 11px;
margin-top: 10px;
display: inline-block;
}
.san{
width: 98px;
height: 52px;
background: #FFFFFF;
border-radius: 6px 6px 6px 6px;
opacity: 1;
border: 1px solid #DDDDDD;
margin-left: 11px;
margin-top: 10px;
display: inline-block;

}
.line{
    width: 345px;
height: 1px;
opacity: 1;
margin-left: 15px;
    margin-top: 8px;
    background: #8CBCFF;
}
.close{
    width: 36px;
    height: 36px;
    margin-top: -36px;
    margin-left: 268px;
    float: left;
}
.tong{
    width: 36px;
    height: 36px;
    margin-top: -38px;
    margin-left: 324px;
    float: left;
}
.top_text{
    color:#FFFFFF;
    /* color: red; */
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 400;
    /* margin-left: 5.58rem; */
    /* font-size: 18px;
font-family: Source Han Sans, Source Han Sans;
font-weight: 400;
color: #FFFFFF; */
/* text-align: center; */
/* margin-top: 30px; */
/* width: 108px;
height: 40px; */
}
.top_bei{
    width: 375px;
    height:150px;
    background: #3074FF;
    border: 1px solid #3074FF;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
.shu{
    background: #3074FF;
    border: none;
    font-size: 20px;
font-family: PingFang SC, PingFang SC;
font-weight: 400;
color: #FFFFFF;
margin-left: 15px;
    margin-top: 42px;
}
input::-webkit-input-placeholder {  /* WebKit browsers*/ 
    font-size: 20px;
font-family: PingFang SC, PingFang SC;
font-weight: 400;
color: #FFFFFF;
}
.da{
    width: 100%;
    height: 100%;
    background: #FAFAFA ;
}
</style>